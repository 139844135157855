<template>
  <v-container
    id="mainLoginPage"
    fluid
    :class="`d-flex ${$vuetify.breakpoint.lgAndUp ? 'align-center' : 'align-start pt-16'} container--fluid background`"
  >
    <v-row
      class="d-flex align-center"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <v-container class="d-flex justify-center align-center">
          <v-img
            contain
            src="../assets/logo.png"
            max-width="100px"
          />
          <span class="text-h2 font-weight-black ml-2">Arizona Facility Services</span>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        class="d-flex justify-center flex-wrap"
        :style="`margin-right: ${$vuetify.breakpoint.lgAndUp ? '15vw' : ''} `"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            v-if="!passwordReset"
            width="500px"
          >
            <v-form
              v-model="isFormValid"
              onsubmit="return false;"
              @submit="submit"
            >
              <v-card-text class="pb-0">
                <v-container class="d-flex justify-center flex-wrap pb-0">
                  <img
                    :src="require(`../assets/${this.$route.params.company && this.$route.params.company != 'afs' ? this.$route.params.company : 'logo'}.${!this.$route.params.company || this.$route.params.company === 'afs' ? 'png': 'svg'}`)"
                  >
                  <v-card-title
                    class="text-h2 font-weight-bold grey--text justify-center pa-0"
                  >
                    Sign In
                  </v-card-title>
                </v-container>
                <v-sheet
                  width="80%"
                  style="margin-right: 10%; margin-left: 10%"
                >
                  <v-text-field
                    id="loginFieldEmail"
                    v-model="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                  />
                  <v-text-field
                    id="loginFieldPassword"
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="'submit'"
                  />
                </v-sheet>
              </v-card-text>
              <v-card-actions class="justify-center pb-0 pt-0">
                <v-btn
                  v-if="!loading"
                  id="loginButtonContinue"
                  :disabled="!isFormValid"
                  color="green darken-1"
                  text
                  width="80%"
                  height="56px"
                  style="font-size: 14px"
                  type="submit"
                >
                  Continue
                </v-btn>
                <v-container
                  v-else
                  style="width: 80%"
                  class="d-flex justify-center align-center green lighten-3 text-center"
                >
                  <v-progress-circular
                    indeterminate
                    color="green"
                  />
                  <span class="ml-2 green--text text--darken-2">Signing in...</span>
                </v-container>
              </v-card-actions>
              <v-container class="d-flex justify-center pb-6 pt-0">
                <div
                  id="loginButtonForgotPassword"
                  tabindex="0"
                  class="reset"
                  @keyup.enter="toggleForgotPassword"
                  @click="toggleForgotPassword"
                >
                  Reset password?
                </div>
              </v-container>
            </v-form>
          </v-card>
          <forgot-password-card
            v-else
            :email="email ? email : ''"
            @alert="handleAlert"
            @reset="toggleForgotPassword"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-alert
            v-if="showStatusMessage"
            id="loginStatusMessage"
            :type="statusMessageType"
            width="500px"
            class="mt-2"
          >
            {{ statusMessage }}
          </v-alert>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import loginApi from '@/api/login.js'
  import cookie from '@/api/cookie.js'
  import ForgotPasswordCard from '@/components/ForgotPasswordCard'
  import { Mode } from '@/util/enums'
  import store from '@/store'
  import { libraryGetByCompany } from '@/assets/companyLibrary'
  import { mdiAccountKey } from '@mdi/js'

  export default {
    name: 'LoginView',
    components: {
      ForgotPasswordCard,
    },
    data: () => ({
      title: 'Sign In',
      email: '',
      password: '',
      showPassword: false,
      loading: false,

      showStatusMessage: false,
      statusMessageType: 'info',
      statusMessage: '',
      statusMessageSuccess: 'An email will be sent to the account provided with instructions on how to change your password.',

      passwordReset: false,
      isFormValid: false,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }),

    methods: {
      submit () {
        if (this.email && this.password) {
          this.loading = true
          this.showStatusMessage = false

          loginApi
            .login(this.email, this.password, libraryGetByCompany(this.$route.params.company))
            .then((response) => {
              console.log(response)
              if (response.status === 200) {
                store.set('user/Role', response.data.userRoles[0].roleId)
                if (response.data.userRoles[0].roleId === 1 || response.data.userRoles[0].roleId === 3) {
                  store.set('app/addItem', {
                    title: 'Admin',
                    icon: mdiAccountKey,
                    to: '/admin',
                  })
                } else {
                  store.set('app/clearItems')
                }
                cookie.createCookie('jwt', response.data.token, 14)
                cookie.createCookie('refreshToken', response.data.refreshToken, 14)

                this.$router.push({ path: '/dashboard/' })
              } else throw new Error()
            })
            .catch((error) => {
              console.log(error)
              if (error.response.status === 401) {
                this.handleAlert(Mode.FAILURE, 'Incorrect Username/Password')
              } else {
                this.handleAlert(Mode.FAILURE, 'We are unable to complete your request at this time. Please try again later.')
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      handleAlert (Mode, message) {
        this.showStatusMessage = true
        if (Mode === 'SUCCESS') {
          this.statusMessageType = 'success'
          this.statusMessage = this.statusMessageSuccess
          this.password = ''
        } else {
          this.statusMessageType = 'error'
          this.statusMessage = message
        }
      },
      toggleForgotPassword () {
        this.showStatusMessage = false
        this.passwordReset = !this.passwordReset
      },
    },
  }
</script>

<style scoped>
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 110vw;
  overflow: hidden;
  background-image: linear-gradient(to right, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  background-size: cover;
}
.reset {
  font-size: 12px;
  color: gray;
}
.reset:hover {
  cursor: pointer;
  color: #701111;
}
@media only screen and (max-width: 1264px) {
  .background {
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  }
}
</style>
